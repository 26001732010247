:root, :host, .sl-theme-light {
  color-scheme: light;
  --sl-color-gray-50: #f9f9f9;
  --sl-color-gray-100: #f4f4f5;
  --sl-color-gray-200: #e4e4e7;
  --sl-color-gray-300: #d4d4d8;
  --sl-color-gray-400: #a1a1aa;
  --sl-color-gray-500: #71717a;
  --sl-color-gray-600: #52525b;
  --sl-color-gray-700: #3f3f46;
  --sl-color-gray-800: #27272a;
  --sl-color-gray-900: #18181b;
  --sl-color-gray-950: #131316;
  --sl-color-red-50: #fef2f2;
  --sl-color-red-100: #fee2e2;
  --sl-color-red-200: #fecaca;
  --sl-color-red-300: #fca5a5;
  --sl-color-red-400: #f87171;
  --sl-color-red-500: #ef4444;
  --sl-color-red-600: #dc2626;
  --sl-color-red-700: #b91c1c;
  --sl-color-red-800: #991b1b;
  --sl-color-red-900: #7f1d1d;
  --sl-color-red-950: #501414;
  --sl-color-orange-50: #fff7ed;
  --sl-color-orange-100: #ffedd5;
  --sl-color-orange-200: #fed7aa;
  --sl-color-orange-300: #fdba74;
  --sl-color-orange-400: #fb923c;
  --sl-color-orange-500: #f97316;
  --sl-color-orange-600: #ea580c;
  --sl-color-orange-700: #c2410c;
  --sl-color-orange-800: #9a3412;
  --sl-color-orange-900: #7c2d12;
  --sl-color-orange-950: #52200f;
  --sl-color-amber-50: #fffbeb;
  --sl-color-amber-100: #fef3c7;
  --sl-color-amber-200: #fde68a;
  --sl-color-amber-300: #fcd34d;
  --sl-color-amber-400: #fbbf24;
  --sl-color-amber-500: #f59e0b;
  --sl-color-amber-600: #d97706;
  --sl-color-amber-700: #b45309;
  --sl-color-amber-800: #92400e;
  --sl-color-amber-900: #78350f;
  --sl-color-amber-950: #4a230b;
  --sl-color-yellow-50: #fefce8;
  --sl-color-yellow-100: #fef9c3;
  --sl-color-yellow-200: #fef08a;
  --sl-color-yellow-300: #fde047;
  --sl-color-yellow-400: #facc15;
  --sl-color-yellow-500: #eab308;
  --sl-color-yellow-600: #ca8a04;
  --sl-color-yellow-700: #a16207;
  --sl-color-yellow-800: #854d0e;
  --sl-color-yellow-900: #713f12;
  --sl-color-yellow-950: #3c260b;
  --sl-color-lime-50: #f7fee7;
  --sl-color-lime-100: #ecfccb;
  --sl-color-lime-200: #d9f99d;
  --sl-color-lime-300: #bef264;
  --sl-color-lime-400: #a3e635;
  --sl-color-lime-500: #84cc16;
  --sl-color-lime-600: #65a30d;
  --sl-color-lime-700: #4d7c0f;
  --sl-color-lime-800: #3f6212;
  --sl-color-lime-900: #365314;
  --sl-color-lime-950: #26390e;
  --sl-color-green-50: #f0fdf4;
  --sl-color-green-100: #dcfce7;
  --sl-color-green-200: #bbf7d0;
  --sl-color-green-300: #86efac;
  --sl-color-green-400: #4ade80;
  --sl-color-green-500: #22c55e;
  --sl-color-green-600: #16a34a;
  --sl-color-green-700: #15803d;
  --sl-color-green-800: #166534;
  --sl-color-green-900: #14532d;
  --sl-color-green-950: #0c311b;
  --sl-color-emerald-50: #ecfdf5;
  --sl-color-emerald-100: #d1fae5;
  --sl-color-emerald-200: #a7f3d0;
  --sl-color-emerald-300: #6ee7b7;
  --sl-color-emerald-400: #34d399;
  --sl-color-emerald-500: #10b981;
  --sl-color-emerald-600: #059669;
  --sl-color-emerald-700: #047857;
  --sl-color-emerald-800: #065f46;
  --sl-color-emerald-900: #064e3b;
  --sl-color-emerald-950: #032d22;
  --sl-color-teal-50: #f0fdfa;
  --sl-color-teal-100: #ccfbf1;
  --sl-color-teal-200: #99f6e4;
  --sl-color-teal-300: #5eead4;
  --sl-color-teal-400: #2dd4bf;
  --sl-color-teal-500: #14b8a6;
  --sl-color-teal-600: #0d9488;
  --sl-color-teal-700: #0f766e;
  --sl-color-teal-800: #115e59;
  --sl-color-teal-900: #134e4a;
  --sl-color-teal-950: #0c2e2c;
  --sl-color-cyan-50: #ecfeff;
  --sl-color-cyan-100: #cffafe;
  --sl-color-cyan-200: #a5f3fc;
  --sl-color-cyan-300: #67e8f9;
  --sl-color-cyan-400: #22d3ee;
  --sl-color-cyan-500: #06b6d4;
  --sl-color-cyan-600: #0891b2;
  --sl-color-cyan-700: #0e7490;
  --sl-color-cyan-800: #155e75;
  --sl-color-cyan-900: #164e63;
  --sl-color-cyan-950: #103442;
  --sl-color-sky-50: #f0f9ff;
  --sl-color-sky-100: #e0f2fe;
  --sl-color-sky-200: #bae6fd;
  --sl-color-sky-300: #7dd3fc;
  --sl-color-sky-400: #38bdf8;
  --sl-color-sky-500: #0ea5e9;
  --sl-color-sky-600: #0284c7;
  --sl-color-sky-700: #0369a1;
  --sl-color-sky-800: #075985;
  --sl-color-sky-900: #0c4a6e;
  --sl-color-sky-950: #0b3249;
  --sl-color-blue-50: #eff6ff;
  --sl-color-blue-100: #dbeafe;
  --sl-color-blue-200: #bfdbfe;
  --sl-color-blue-300: #93c5fd;
  --sl-color-blue-400: #60a5fa;
  --sl-color-blue-500: #3b82f6;
  --sl-color-blue-600: #2563eb;
  --sl-color-blue-700: #1d4ed8;
  --sl-color-blue-800: #1e40af;
  --sl-color-blue-900: #1e3a8a;
  --sl-color-blue-950: #152149;
  --sl-color-indigo-50: #eef2ff;
  --sl-color-indigo-100: #e0e7ff;
  --sl-color-indigo-200: #c7d2fe;
  --sl-color-indigo-300: #a5b4fc;
  --sl-color-indigo-400: #818cf8;
  --sl-color-indigo-500: #6366f1;
  --sl-color-indigo-600: #4f46e5;
  --sl-color-indigo-700: #4338ca;
  --sl-color-indigo-800: #3730a3;
  --sl-color-indigo-900: #312e81;
  --sl-color-indigo-950: #242154;
  --sl-color-violet-50: #f5f3ff;
  --sl-color-violet-100: #ede9fe;
  --sl-color-violet-200: #ddd6fe;
  --sl-color-violet-300: #c4b5fd;
  --sl-color-violet-400: #a78bfa;
  --sl-color-violet-500: #8b5cf6;
  --sl-color-violet-600: #7c3aed;
  --sl-color-violet-700: #6d28d9;
  --sl-color-violet-800: #5b21b6;
  --sl-color-violet-900: #4c1d95;
  --sl-color-violet-950: #311558;
  --sl-color-purple-50: #faf5ff;
  --sl-color-purple-100: #f3e8ff;
  --sl-color-purple-200: #e9d5ff;
  --sl-color-purple-300: #d8b4fe;
  --sl-color-purple-400: #c084fc;
  --sl-color-purple-500: #a855f7;
  --sl-color-purple-600: #9333ea;
  --sl-color-purple-700: #7e22ce;
  --sl-color-purple-800: #6b21a8;
  --sl-color-purple-900: #581c87;
  --sl-color-purple-950: #2f1143;
  --sl-color-fuchsia-50: #fdf4ff;
  --sl-color-fuchsia-100: #fae8ff;
  --sl-color-fuchsia-200: #f5d0fe;
  --sl-color-fuchsia-300: #f0abfc;
  --sl-color-fuchsia-400: #e879f9;
  --sl-color-fuchsia-500: #d946ef;
  --sl-color-fuchsia-600: #c026d3;
  --sl-color-fuchsia-700: #a21caf;
  --sl-color-fuchsia-800: #86198f;
  --sl-color-fuchsia-900: #701a75;
  --sl-color-fuchsia-950: #38103a;
  --sl-color-pink-50: #fdf2f8;
  --sl-color-pink-100: #fce7f3;
  --sl-color-pink-200: #fbcfe8;
  --sl-color-pink-300: #f9a8d4;
  --sl-color-pink-400: #f472b6;
  --sl-color-pink-500: #ec4899;
  --sl-color-pink-600: #db2777;
  --sl-color-pink-700: #be185d;
  --sl-color-pink-800: #9d174d;
  --sl-color-pink-900: #831843;
  --sl-color-pink-950: #430e23;
  --sl-color-rose-50: #fff1f2;
  --sl-color-rose-100: #ffe4e6;
  --sl-color-rose-200: #fecdd3;
  --sl-color-rose-300: #fda4af;
  --sl-color-rose-400: #fb7185;
  --sl-color-rose-500: #f43f5e;
  --sl-color-rose-600: #e11d48;
  --sl-color-rose-700: #be123c;
  --sl-color-rose-800: #9f1239;
  --sl-color-rose-900: #881337;
  --sl-color-rose-950: #4a0d20;
  --sl-color-primary-50: var(--sl-color-sky-50);
  --sl-color-primary-100: var(--sl-color-sky-100);
  --sl-color-primary-200: var(--sl-color-sky-200);
  --sl-color-primary-300: var(--sl-color-sky-300);
  --sl-color-primary-400: var(--sl-color-sky-400);
  --sl-color-primary-500: var(--sl-color-sky-500);
  --sl-color-primary-600: var(--sl-color-sky-600);
  --sl-color-primary-700: var(--sl-color-sky-700);
  --sl-color-primary-800: var(--sl-color-sky-800);
  --sl-color-primary-900: var(--sl-color-sky-900);
  --sl-color-primary-950: var(--sl-color-sky-950);
  --sl-color-success-50: var(--sl-color-green-50);
  --sl-color-success-100: var(--sl-color-green-100);
  --sl-color-success-200: var(--sl-color-green-200);
  --sl-color-success-300: var(--sl-color-green-300);
  --sl-color-success-400: var(--sl-color-green-400);
  --sl-color-success-500: var(--sl-color-green-500);
  --sl-color-success-600: var(--sl-color-green-600);
  --sl-color-success-700: var(--sl-color-green-700);
  --sl-color-success-800: var(--sl-color-green-800);
  --sl-color-success-900: var(--sl-color-green-900);
  --sl-color-success-950: var(--sl-color-green-950);
  --sl-color-warning-50: var(--sl-color-amber-50);
  --sl-color-warning-100: var(--sl-color-amber-100);
  --sl-color-warning-200: var(--sl-color-amber-200);
  --sl-color-warning-300: var(--sl-color-amber-300);
  --sl-color-warning-400: var(--sl-color-amber-400);
  --sl-color-warning-500: var(--sl-color-amber-500);
  --sl-color-warning-600: var(--sl-color-amber-600);
  --sl-color-warning-700: var(--sl-color-amber-700);
  --sl-color-warning-800: var(--sl-color-amber-800);
  --sl-color-warning-900: var(--sl-color-amber-900);
  --sl-color-warning-950: var(--sl-color-amber-950);
  --sl-color-danger-50: var(--sl-color-red-50);
  --sl-color-danger-100: var(--sl-color-red-100);
  --sl-color-danger-200: var(--sl-color-red-200);
  --sl-color-danger-300: var(--sl-color-red-300);
  --sl-color-danger-400: var(--sl-color-red-400);
  --sl-color-danger-500: var(--sl-color-red-500);
  --sl-color-danger-600: var(--sl-color-red-600);
  --sl-color-danger-700: var(--sl-color-red-700);
  --sl-color-danger-800: var(--sl-color-red-800);
  --sl-color-danger-900: var(--sl-color-red-900);
  --sl-color-danger-950: var(--sl-color-red-950);
  --sl-color-neutral-50: var(--sl-color-gray-50);
  --sl-color-neutral-100: var(--sl-color-gray-100);
  --sl-color-neutral-200: var(--sl-color-gray-200);
  --sl-color-neutral-300: var(--sl-color-gray-300);
  --sl-color-neutral-400: var(--sl-color-gray-400);
  --sl-color-neutral-500: var(--sl-color-gray-500);
  --sl-color-neutral-600: var(--sl-color-gray-600);
  --sl-color-neutral-700: var(--sl-color-gray-700);
  --sl-color-neutral-800: var(--sl-color-gray-800);
  --sl-color-neutral-900: var(--sl-color-gray-900);
  --sl-color-neutral-950: var(--sl-color-gray-950);
  --sl-color-neutral-0: #fff;
  --sl-color-neutral-1000: #000;
  --sl-border-radius-small: .1875rem;
  --sl-border-radius-medium: .25rem;
  --sl-border-radius-large: .5rem;
  --sl-border-radius-x-large: 1rem;
  --sl-border-radius-circle: 50%;
  --sl-border-radius-pill: 9999px;
  --sl-shadow-x-small: 0 1px 2px #71717a0f;
  --sl-shadow-small: 0 1px 2px #71717a1f;
  --sl-shadow-medium: 0 2px 4px #71717a1f;
  --sl-shadow-large: 0 2px 8px #71717a1f;
  --sl-shadow-x-large: 0 4px 16px #71717a1f;
  --sl-spacing-3x-small: .125rem;
  --sl-spacing-2x-small: .25rem;
  --sl-spacing-x-small: .5rem;
  --sl-spacing-small: .75rem;
  --sl-spacing-medium: 1rem;
  --sl-spacing-large: 1.25rem;
  --sl-spacing-x-large: 1.75rem;
  --sl-spacing-2x-large: 2.25rem;
  --sl-spacing-3x-large: 3rem;
  --sl-spacing-4x-large: 4.5rem;
  --sl-transition-x-slow: 1s;
  --sl-transition-slow: .5s;
  --sl-transition-medium: .25s;
  --sl-transition-fast: .15s;
  --sl-transition-x-fast: 50ms;
  --sl-font-mono: SFMono-Regular, Consolas, "Liberation Mono", Menlo, monospace;
  --sl-font-sans: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --sl-font-serif: Georgia, "Times New Roman", serif;
  --sl-font-size-2x-small: .625rem;
  --sl-font-size-x-small: .75rem;
  --sl-font-size-small: .875rem;
  --sl-font-size-medium: 1rem;
  --sl-font-size-large: 1.25rem;
  --sl-font-size-x-large: 1.5rem;
  --sl-font-size-2x-large: 2.25rem;
  --sl-font-size-3x-large: 3rem;
  --sl-font-size-4x-large: 4.5rem;
  --sl-font-weight-light: 300;
  --sl-font-weight-normal: 400;
  --sl-font-weight-semibold: 500;
  --sl-font-weight-bold: 700;
  --sl-letter-spacing-denser: -.03em;
  --sl-letter-spacing-dense: -.015em;
  --sl-letter-spacing-normal: normal;
  --sl-letter-spacing-loose: .075em;
  --sl-letter-spacing-looser: .15em;
  --sl-line-height-denser: 1;
  --sl-line-height-dense: 1.4;
  --sl-line-height-normal: 1.8;
  --sl-line-height-loose: 2.2;
  --sl-line-height-looser: 2.6;
  --sl-focus-ring-color: var(--sl-color-primary-600);
  --sl-focus-ring-style: solid;
  --sl-focus-ring-width: 3px;
  --sl-focus-ring: var(--sl-focus-ring-style) var(--sl-focus-ring-width) var(--sl-focus-ring-color);
  --sl-focus-ring-offset: 1px;
  --sl-button-font-size-small: var(--sl-font-size-x-small);
  --sl-button-font-size-medium: var(--sl-font-size-small);
  --sl-button-font-size-large: var(--sl-font-size-medium);
  --sl-input-height-small: 1.875rem;
  --sl-input-height-medium: 2.5rem;
  --sl-input-height-large: 3.125rem;
  --sl-input-background-color: var(--sl-color-neutral-0);
  --sl-input-background-color-hover: var(--sl-input-background-color);
  --sl-input-background-color-focus: var(--sl-input-background-color);
  --sl-input-background-color-disabled: var(--sl-color-neutral-100);
  --sl-input-border-color: var(--sl-color-neutral-300);
  --sl-input-border-color-hover: var(--sl-color-neutral-400);
  --sl-input-border-color-focus: var(--sl-color-primary-500);
  --sl-input-border-color-disabled: var(--sl-color-neutral-300);
  --sl-input-border-width: 1px;
  --sl-input-required-content: "*";
  --sl-input-required-content-offset: -2px;
  --sl-input-required-content-color: var(--sl-input-label-color);
  --sl-input-border-radius-small: var(--sl-border-radius-medium);
  --sl-input-border-radius-medium: var(--sl-border-radius-medium);
  --sl-input-border-radius-large: var(--sl-border-radius-medium);
  --sl-input-font-family: var(--sl-font-sans);
  --sl-input-font-weight: var(--sl-font-weight-normal);
  --sl-input-font-size-small: var(--sl-font-size-small);
  --sl-input-font-size-medium: var(--sl-font-size-medium);
  --sl-input-font-size-large: var(--sl-font-size-large);
  --sl-input-letter-spacing: var(--sl-letter-spacing-normal);
  --sl-input-color: var(--sl-color-neutral-700);
  --sl-input-color-hover: var(--sl-color-neutral-700);
  --sl-input-color-focus: var(--sl-color-neutral-700);
  --sl-input-color-disabled: var(--sl-color-neutral-900);
  --sl-input-icon-color: var(--sl-color-neutral-500);
  --sl-input-icon-color-hover: var(--sl-color-neutral-600);
  --sl-input-icon-color-focus: var(--sl-color-neutral-600);
  --sl-input-placeholder-color: var(--sl-color-neutral-500);
  --sl-input-placeholder-color-disabled: var(--sl-color-neutral-600);
  --sl-input-spacing-small: var(--sl-spacing-small);
  --sl-input-spacing-medium: var(--sl-spacing-medium);
  --sl-input-spacing-large: var(--sl-spacing-large);
  --sl-input-focus-ring-color: #0ea5e966;
  --sl-input-focus-ring-offset: 0;
  --sl-input-filled-background-color: var(--sl-color-neutral-100);
  --sl-input-filled-background-color-hover: var(--sl-color-neutral-100);
  --sl-input-filled-background-color-focus: var(--sl-color-neutral-100);
  --sl-input-filled-background-color-disabled: var(--sl-color-neutral-100);
  --sl-input-filled-color: var(--sl-color-neutral-800);
  --sl-input-filled-color-hover: var(--sl-color-neutral-800);
  --sl-input-filled-color-focus: var(--sl-color-neutral-700);
  --sl-input-filled-color-disabled: var(--sl-color-neutral-800);
  --sl-input-label-font-size-small: var(--sl-font-size-small);
  --sl-input-label-font-size-medium: var(--sl-font-size-medium);
  --sl-input-label-font-size-large: var(--sl-font-size-large);
  --sl-input-label-color: inherit;
  --sl-input-help-text-font-size-small: var(--sl-font-size-x-small);
  --sl-input-help-text-font-size-medium: var(--sl-font-size-small);
  --sl-input-help-text-font-size-large: var(--sl-font-size-medium);
  --sl-input-help-text-color: var(--sl-color-neutral-500);
  --sl-toggle-size-small: .875rem;
  --sl-toggle-size-medium: 1.125rem;
  --sl-toggle-size-large: 1.375rem;
  --sl-overlay-background-color: #71717a54;
  --sl-panel-background-color: var(--sl-color-neutral-0);
  --sl-panel-border-color: var(--sl-color-neutral-200);
  --sl-panel-border-width: 1px;
  --sl-tooltip-border-radius: var(--sl-border-radius-medium);
  --sl-tooltip-background-color: var(--sl-color-neutral-800);
  --sl-tooltip-color: var(--sl-color-neutral-0);
  --sl-tooltip-font-family: var(--sl-font-sans);
  --sl-tooltip-font-weight: var(--sl-font-weight-normal);
  --sl-tooltip-font-size: var(--sl-font-size-small);
  --sl-tooltip-line-height: var(--sl-line-height-dense);
  --sl-tooltip-padding: var(--sl-spacing-2x-small) var(--sl-spacing-x-small);
  --sl-tooltip-arrow-size: 6px;
  --sl-z-index-drawer: 700;
  --sl-z-index-dialog: 800;
  --sl-z-index-dropdown: 900;
  --sl-z-index-toast: 950;
  --sl-z-index-tooltip: 1000;
}

@supports (scrollbar-gutter: stable) {
  .sl-scroll-lock {
    scrollbar-gutter: var(--sl-scroll-lock-gutter) !important;
  }

  .sl-scroll-lock body {
    overflow: hidden !important;
  }
}

@supports not (scrollbar-gutter: stable) {
  .sl-scroll-lock body {
    padding-right: var(--sl-scroll-lock-size) !important;
    overflow: hidden !important;
  }
}

.sl-toast-stack {
  z-index: var(--sl-z-index-toast);
  top: 0;
  width: 28rem;
  max-width: 100%;
  max-height: 100%;
  position: fixed;
  inset-inline-end: 0;
  overflow: auto;
}

.sl-toast-stack sl-alert {
  margin: var(--sl-spacing-medium);
}

.sl-toast-stack sl-alert::part(base) {
  box-shadow: var(--sl-shadow-large);
}
/*# sourceMappingURL=add_review.d29c97f7.css.map */
